import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData';
import { getUnixTime, startOfDay } from 'date-fns';
import { ClinicContext, useGraphqlClient } from '@/App';
import { AppointBlockPresenter } from '../presenter/AppointBlockPresenter';
import { useNavigate } from '@tanstack/react-location';
import { useFacilityNameList_service } from '@/domain';
import { MutateContext } from '@/provider/common/MutateProvider';
import type { GetBlockQuery } from '@/_graphql/graphql-client';
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client';
import { SetDateContext } from '@/components/Main/provider/MainProvider';
import { useCommonParams } from '@/domain/Common/useCommonParams';
import type { BlockType } from '../../entities';
import type { UseQueryResult } from 'react-query';

const isClinicTimeConfig = (config: any): config is { __typename: "ClinicTimeConfig", clinicTime?: { endTime: any, startTime: any } | null } => {
    return config?.__typename === "ClinicTimeConfig";
};

interface AppointBlockViewsType {
    blockId?: string;
    startTime: number;
    endTime: number;
    facilityId: string;
    operation: "add" | "reference" | "edit" | "copy" | "update" | undefined
    block: BlockType
    req: UseQueryResult<GetBlockQuery, unknown>
    minTreatmentTime: number
    stepsMinutes: number
}

interface BlockFormType {
    block: {
        baseStartTime: number
        startTime: number;
        facilityId: string;
        endTime: number;
        clinic_id: string;
        faclityId: string;
        remarks: string;
        color: string;
        isNonAccept: boolean;
        isDelete: boolean;
        id: string;

    };
}

export const AppointBlockViews: React.FC<AppointBlockViewsType> = ({
    blockId,
    startTime,
    endTime,
    facilityId,
    operation,
    block,
    req,
    minTreatmentTime,
    stepsMinutes,
}) => {
    const clinic = useContext(ClinicContext);
    const navigate = useNavigate();
    const graphqlClient = useGraphqlClient();

    const methods = useForm<BlockFormType>({
        defaultValues: {},
    });

    const isBlockLoading = Boolean(blockId) && (req.status === 'loading')

    useEffect(() => {
        if (operation === 'reference') {
            methods.setValue('block', { ...block } as any)
            methods.setValue('block.baseStartTime', block.startTime)
            methods.setValue('block.clinic_id', clinic.clinic_id)
        }
        // 依存引数をコンパクトにしたい
    }, [operation, block.id, block.endTime, block.color, block.facilityId, block.remarks])

    useEffect(() => {
        switch (operation) {
            case 'add':
                // initNewBlock
                methods.setValue('block.id', '')
                methods.setValue('block.facilityId', facilityId);
                methods.setValue('block.startTime', startTime);
                methods.setValue('block.endTime', Number(endTime));
                methods.setValue('block.clinic_id', clinic.clinic_id);
                methods.setValue('block.color', '#ddd')
                methods.setValue('block.remarks', '')
                break;

            case 'edit':
                // 編集モード中の処理はシフト枠(ReservationShiftPresenter)が担保
                alert('editBlocks')
                break;

            case 'update':
                methods.setValue('block.facilityId', facilityId);
                methods.setValue('block.startTime', startTime);
                methods.setValue('block.endTime', Number(endTime));
                break

            default:
                break;
        }
    }, [startTime, facilityId])

    const { setEndTime } = useCommonParams()
    const { nowDate } = useContext(SetDateContext)
    const activeClinicTimeConfig = useGetLatestClinicConfigQuery(graphqlClient, { 
        clinic_id: clinic.clinic_id, 
        current_date: getUnixTime(startOfDay(nowDate)), 
        config_type: 'clinic_time' 
    })
    const configData = activeClinicTimeConfig.data?.getLatestClinicConfig?.config ?? null;
    const activeClinicTime = configData !== null && isClinicTimeConfig(configData) ? configData.clinicTime : null;
    const clinicTime = activeClinicTime ?? emptyClinicTimeConfig.config.clinicTime;

    const mutates = useContext(MutateContext);

    const allFacilityNameList = useFacilityNameList_service(graphqlClient, {
        clinic_id: clinic.clinic_id,
    });
    const facilityNameList = allFacilityNameList.facilityNameList;

    const isClinicReady = clinic.clinic_id !== undefined && clinic.clinic_id !== '';

    const formData = methods.watch('block');

    return isClinicReady && startTime !== undefined ? (
        <FormProvider {...methods}>
            {formData?.endTime != null && req.status !== 'loading' ? (
                <AppointBlockPresenter
                    clinic={clinic}
                    clinicTime={clinicTime}
                    methods={methods}
                    facilityNameList={facilityNameList}
                    mutates={mutates}
                    formData={formData}
                    navigate={navigate}
                    operation={operation}
                    setEndTime={setEndTime}
                    isBlockLoading={isBlockLoading}
                    minTreatmentTime={minTreatmentTime}
                    stepsMinutes={stepsMinutes}
                />
            ) : (
                <></>
            )}
        </FormProvider>
    ) : (
        <></>
    );
};