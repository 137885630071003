import { type FC } from 'react'
import { Button } from '@mantine/core'
import { type ClinicConfig } from '@/_graphql/graphql-client'
import { format, fromUnixTime } from 'date-fns'
import { UpsertCardWidthForm } from './UpsertCardWidthForm'

// スタイル
const deleteBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-red-700 hover:bg-red-900'
const disableBtnCss: string = 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

interface CardWidthTableRowProps {
  config: ClinicConfig
  editing: string
  selections: string[]
  clinicId: string
  toggleRow: (id: string) => void
  setEditing: (id: string) => void
  handleUpdate: (configId: string, params: { cardWidth: number; startDate: number; endDate?: number | null }) => void
  deleteClinicConfig: (params: { clinic_id: string; config_id: string; config_type: string }) => void
  isAdmin: boolean
}

const EditModeRow: FC<{
  config: ClinicConfig
  handleUpdate: (configId: string, params: { cardWidth: number; startDate: number; endDate?: number | null }) => void
  setEditing: (id: string) => void
}> = ({ config, handleUpdate, setEditing }) => {
  if (config.config.__typename !== 'CardWidthConfig') {
    return null
  }

  return (
    <tr>
      <td colSpan={7}>
        <UpsertCardWidthForm
          config={{
            __typename: 'CardWidthConfig',
            cardWidth: config.config.cardWidth,
            startDate: config.startDate,
            endDate: config.endDate
          }}
          onSubmit={(params): void => {
            handleUpdate(config.config_id, params)
          }}
          onCancel={(): void => {
            setEditing('')
          }}
        />
      </td>
    </tr>
  )
}

const ViewModeRow: FC<{
  config: ClinicConfig
  selections: string[]
  clinicId: string
  toggleRow: (id: string) => void
  setEditing: (id: string) => void
  deleteClinicConfig: (params: { clinic_id: string; config_id: string; config_type: string }) => void
  isAdmin: boolean
}> = ({ config, selections, clinicId, toggleRow, setEditing, deleteClinicConfig, isAdmin }) => (
  <tr className="bg-white border-b hover:bg-gray-50">
    <td className="w-4 p-4">
      <input
        type="checkbox"
        className="cursor-pointer"
        checked={selections.includes(config.config_id)}
        onChange={(): void => {
          toggleRow(config.config_id)
        }}
      />
    </td>
    <td>
      {config.config.__typename === 'CardWidthConfig' && config.config.cardWidth}
    </td>
    <td>{format(fromUnixTime(config.startDate), 'yyyy/MM/dd')}</td>
    <td>
      {config.endDate !== undefined && config.endDate !== null
        ? format(fromUnixTime(config.endDate), 'yyyy/MM/dd')
        : '-'}
    </td>
    <td>
      {config.created_at !== undefined && config.created_at !== null
        ? format(fromUnixTime(config.created_at), 'yyyy/MM/dd')
        : '-'}
    </td>
    <td>
      {config.updated_at !== undefined && config.updated_at !== null
        ? format(fromUnixTime(config.updated_at), 'yyyy/MM/dd')
        : '-'}
    </td>
    <td>
      <div className="flex gap-2 h-full">
        <Button
          disabled={!isAdmin}
          className={disableBtnCss + ' cursor-pointer'}
          onClick={(): void => {
            setEditing(config.config_id)
          }}
        >
          編集
        </Button>
        <Button
          disabled={!isAdmin}
          className={`${
            selections.includes(config.config_id)
              ? deleteBtnCss + ' cursor-pointer'
              : disableBtnCss
          }`}
          onClick={(): void => {
            if (selections.includes(config.config_id)) {
              deleteClinicConfig({
                clinic_id: clinicId,
                config_id: config.config_id,
                config_type: 'card_width'
              })
            }
          }}
        >
          削除
        </Button>
      </div>
    </td>
  </tr>
)

export const CardWidthTableRow: FC<CardWidthTableRowProps> = ({
  config,
  editing,
  selections,
  clinicId,
  toggleRow,
  setEditing,
  handleUpdate,
  deleteClinicConfig,
  isAdmin,
}): JSX.Element => {
  if (editing === config.config_id) {
    return (
      <EditModeRow
        config={config}
        handleUpdate={handleUpdate}
        setEditing={setEditing}
      />
    )
  }

  return (
    <ViewModeRow
      config={config}
      selections={selections}
      clinicId={clinicId}
      toggleRow={isAdmin ? toggleRow : (): void => {}}
      setEditing={isAdmin ? setEditing : (): void => {}}
      deleteClinicConfig={isAdmin ? deleteClinicConfig : (): void => {}}
      isAdmin={isAdmin}
    />
  )
} 