import React, { useContext, useEffect, useState } from 'react'
import { useAddMenuMutation, AddMenuMutationVariables } from '../../../../../../.graphql/types'
import { useQueryClient } from 'react-query'
import { medicalRole, medicalStatus, medicalSubjects } from '../../common/medicalMenu'
import { CategoryType } from '../../common/type'
import { emptyCategory } from '../../common/emptyData'
import { ClinicContext } from '@/App'
import { useGraphqlClient } from '@/App';
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client'
import { getUnixTime, startOfDay } from 'date-fns'
import { getTreatmentMinutes, getTreatmentTimes } from '@/components/Common/utils/common/addData'

interface MedicalCareMenuAddRecordType {
  setShow: (show: boolean) => void
  indexNum: number
  categoryList: CategoryType[]
}

function getSelectableCategorys(
  categoryList: CategoryType[],
  subject: string
) {
  const subjectCategorys = categoryList.filter(category => {
    return category.mainCategory === subject
  })
  console.log(subjectCategorys)
  return subjectCategorys;
}

const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
    return config?.__typename === "CardWidthConfig";
};

export const MedicalCareMenuAddRecord: React.FC<MedicalCareMenuAddRecordType> = ({ setShow, indexNum, categoryList }): JSX.Element => {
  const graphqlClient = useGraphqlClient()
  const clinic_id = useContext(ClinicContext).clinic_id;

  // clinic設定の取得
  const { data: cardWidthList, refetch } = useGetLatestClinicConfigQuery(graphqlClient, {
      clinic_id,
      current_date: getUnixTime(startOfDay(new Date())),
      config_type: 'card_width',
  })

  const configData = cardWidthList?.getLatestClinicConfig?.config ?? null;
  const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 15;
  const maxTreatmentMinutes = 180;
  const minTreatmentTime = cardWidth;

  const treatmentTimes = getTreatmentMinutes(maxTreatmentMinutes, minTreatmentTime, cardWidth);
  // const treatmentTimes = getTreatmentTimes(minTreatmentTime, treatmentMinutes);

  const firstElement: number = 0
  const [selectableCategoryList, setSelectableCategoryList] = useState([emptyCategory])
  const [treatmentName, setTreatmentName] = useState('')
  const [abbreviation, setAbbreviation] = useState('')
  const [amount, setAmount] = useState(0)
  const [subCategoryId, setSubCategoryId] = useState('');
  const [treatmentTime, setTreatmentTime] = useState(treatmentTimes[firstElement])
  const [subject, setSubject] = useState(medicalSubjects[firstElement])
  const [role, setRole] = useState(medicalRole[firstElement])
  const [menuStatus, setMenuStatus] = useState(medicalStatus[firstElement])

  useEffect(() => {
    if (subject.length !== 0) {
      const _selectableCategoryList = getSelectableCategorys(categoryList, subject);
      setSubCategoryId(_selectableCategoryList[0]?.id)
      setSelectableCategoryList(_selectableCategoryList);
    }
  }, [subject])

  const queryClient = useQueryClient()

  const addMenu = useAddMenuMutation(graphqlClient, {
    onSettled: () => {
      queryClient.invalidateQueries('listMenu').catch(() => console.log('on Settled'))
    },
    onSuccess: res => {
      console.log(res)
    },
    onError: error => {
      throw error
    },
    retry: 3
  })

  const addMenuData: AddMenuMutationVariables = {
    amount,
    role,
    name: treatmentName,
    abbreviation,
    subCategoryId: subCategoryId,
    parts: '',
    status: menuStatus,
    type: subject,
    time: treatmentTime,
    isDelete: false,
    index: indexNum,
    clinic_id
  }

  if (addMenuData.parts === null) delete addMenuData.parts
  const addMenuExecute = (): void => {
    addMenu.mutate(addMenuData)
  }
  return (
    <>
      <form action='#'>
        <table>
          <tbody>
            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
              <th className='w-1/12 p-4'>
                <div className='flex items-center'>
                  <input
                    id='checkbox-table-search-1'
                    type='checkbox'
                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  />
                </div>
              </th>
              <td
                scope='row'
                className='w-3/12 font-medium text-gray-900 dark:text-white whitespace-nowrap'
              >
                <input
                  type='text'
                  id='treatmentName'
                  className='bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='正式名称'
                  required
                  onChange={e => setTreatmentName(e.target.value)}
                />
              </td>
              <td
                scope='row'
                className='w-3/12 font-medium text-gray-900 dark:text-white whitespace-nowrap'
              >
                <input
                  type='text'
                  id='treatmentName'
                  className='bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='略称'
                  required
                  onChange={e => setAbbreviation(e.target.value)}
                />
              </td>
              {/* <td className='w-1/12 px-2 py-4'>
                <select
                  id='parts'
                  className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  onChange={e => setPart(e.target.value)}
                >
                  {medicalParts.map((part: string, index: number) => (
                    <option value={part} key={index}>
                      {part}
                    </option>
                  ))}
                </select>
              </td> */}
              {/* <td className='w-1/12 px-2 py-4'>
                <input
                  type='number'
                  id='amount'
                  className='text-right w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='3980'
                  required
                  onChange={e => setAmount(Number(e.target.value))}
                />
              </td> */}
              <td className='w-1/12 px-2 py-4'>
                <select
                  id='treatmentTimes'
                  className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  onChange={e => setTreatmentTime(Number(e.target.value))}
                >
                  {treatmentTimes.map((time: number, index: number) => (
                    <option value={time} key={index}>
                      {time}
                    </option>
                  ))}
                </select>
              </td>
              <td className='w-1/12 px-2 py-4'>
                <select
                  id='subject'
                  className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  onChange={e => setSubject(e.target.value)}
                >
                  {medicalSubjects.map((subject: string, index: number) => (
                    <option value={subject} key={index}>
                      {subject}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  name="subCategory"
                  id="subCategory"
                  // className={`${selectableCategoryList[0]?.id.length !== 0 ? "opacity-50 pointer-events-none" : "opacity-100 pointer-events-visible"}`}
                  onChange={(e) => { setSubCategoryId(e.target.value) }}>
                  {
                    selectableCategoryList.map(category => {
                      return (
                        <option value={category.id} key={category.id}>
                          {category.name}
                        </option>
                      )
                    })
                  }
                </select>
              </td>
              <td className='w-1/12 px-2 py-4'>
                <select
                  id='maneger'
                  className='w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  onChange={e => setRole(e.target.value)}
                >
                  <option hidden></option>
                  {medicalRole.map((role: string, index: number) => (
                    <option value={role} key={index}>
                      {role}
                    </option>
                  ))}
                </select>
              </td>
              <td className='w-1/12 px-2 py-4'>
                <select
                  id='status'
                  className='w-full g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  onChange={e => setMenuStatus(e.target.value)}
                >
                  {medicalStatus.map((state: string, index: number) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </select>
              </td>
              <td className='w-1/12 pr-4 py-4 text-right'>
                <button
                  type='submit'
                  className='leading-8 text-gray-100 text-sm font-medium h-8 w-20 text-center rounded-2xl bg-red-500 hover:bg-red-600'
                  onClick={() => {
                    addMenuExecute()
                    setShow(false)
                  }}
                >
                  保存
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </>
  )
}
