import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { addDate, formatDateForJapan, subDate } from '@/components/Common/utils';
import { useIsMobileContext } from '@/provider/common/IsMobileProvider';
import ReactDatePicker from 'react-datepicker';
import { DateSelectorPropsType } from '../../../../entities';
import { useMediaQuery } from '@mantine/hooks';

const wrapperStyle = "flex items-bottom text-sm"
const datePickerStyle = "text-xs"
const arrowStyle = "h-2 w-2"

export const DateSelector: React.FC<DateSelectorPropsType> = ({
    nowDate,
    setNowDate,
    isMobile
}) => {
    const isValidDate = (date: Date): boolean => {
        return date instanceof Date && !isNaN(date.getTime());
    };

    const formatDate = (date: Date): string => {
        if (!isValidDate(date)) {
            console.error('Invalid date object:', date);
            return '';
        }
        return formatDateForJapan(date);
    };

    return (
        <div className={wrapperStyle}>
            <ArrowLeftIcon
                onClick={() => { subDate(nowDate, setNowDate); }}
                className={arrowStyle}
            />
            {
                isMobile ?
                    <ReactDatePicker
                        popperProps={{ strategy: 'fixed' }}
                        className={datePickerStyle}
                        selected={isValidDate(nowDate) ? nowDate : new Date()}
                        onChange={(date) => {
                            if (date && isValidDate(date)) {
                                setNowDate(date);
                            }
                        }}
                        dateFormat="yyyy/MM/dd"
                    />
                    :
                    formatDate(nowDate)
            }

            <ArrowRightIcon
                onClick={() => { addDate(nowDate, setNowDate); }}
                className={arrowStyle}
            />
        </div>
    )
}
