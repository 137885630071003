import { type FC, useState } from 'react'
import { Box, Button, NumberInput, TextInput } from '@mantine/core'
import { format, fromUnixTime } from 'date-fns'
import { type CardWidthConfig } from '@/_graphql/graphql-client'

interface UpsertCardWidthFormProps {
  config?: {
    __typename: 'CardWidthConfig'
    cardWidth: number
    startDate?: number
    endDate?: number | null
  }
  onSubmit: (params: {
    cardWidth: number
    startDate: number
    endDate?: number | null
  }) => void
  onCancel: () => void
}

export const UpsertCardWidthForm: FC<UpsertCardWidthFormProps> = ({
  config,
  onSubmit,
  onCancel,
}): JSX.Element => {
  const defaultCardWidth = 10
  const [cardWidth, setCardWidth] = useState<number>(() => {
    if (config?.cardWidth != null) {
      return config.cardWidth
    }
    return defaultCardWidth
  })

  const [startDate, setStartDate] = useState<string>(() => {
    if (config?.startDate != null) {
      return format(fromUnixTime(config.startDate), 'yyyy-MM-dd')
    }
    return format(new Date(), 'yyyy-MM-dd')
  })

  const [endDate, setEndDate] = useState<string>(() => {
    if (config?.endDate != null) {
      return format(fromUnixTime(config.endDate), 'yyyy-MM-dd')
    }
    return ''
  })

  const handleSubmit = (): void => {
    const startTimestamp = new Date(startDate).getTime() / 1000
    const endTimestamp = endDate !== '' ? new Date(endDate).getTime() / 1000 : null

    onSubmit({
      cardWidth,
      startDate: startTimestamp,
      endDate: endTimestamp
    })
  }

  return (
    <Box className="p-4 space-y-4">
      <NumberInput
        label="予約枠(分)"
        value={cardWidth}
        onChange={(value): void => {
          if (typeof value === 'number') {
            setCardWidth(value)
          }
        }}
        min={1}
        max={100}
        step={5}
      />
      <TextInput
        type="date"
        label="開始日"
        value={startDate}
        onChange={(e): void => {
          setStartDate(e.currentTarget.value)
        }}
        required
      />
      <TextInput
        type="date"
        label="終了日"
        value={endDate}
        onChange={(e): void => {
          setEndDate(e.currentTarget.value)
        }}
      />
      <div className="flex gap-2">
        <Button variant="outline" onClick={handleSubmit}>保存</Button>
        <Button variant="outline" color="red"onClick={onCancel}>
          キャンセル
        </Button>
      </div>
    </Box>
  )
} 