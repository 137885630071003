import { useState } from 'react'
import { type Clinic, type ClinicConfig } from '@/_graphql/graphql-client'
import { generateSortedConfigList } from '../../utils/libs/common'

interface UseCardWidthPresenterProps {
  clinic: Clinic
  cardWidthList: ClinicConfig[]
  addCardWidthConfig: (params: {
    clinic_id: string
    config: { cardWidth: number }
    startDate: number
    endDate?: number | null
  }) => void
  updateCardWidthConfig: (params: {
    clinic_id: string
    config_id: string
    config: { cardWidth: number }
    startDate: number
    endDate?: number | null
  }) => void
}

export const useCardWidthPresenter = ({
  clinic,
  cardWidthList,
  addCardWidthConfig,
  updateCardWidthConfig
}: UseCardWidthPresenterProps) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [sortBy, setSortBy] = useState<'created_at' | 'updated_at'>('created_at')
  const [selections, setSelections] = useState<string[]>([])
  const [editing, setEditing] = useState('')
  const [isOpenAddRecord, setIsOpenAddRecord] = useState(false)

  const sortedCardWidthList = generateSortedConfigList(cardWidthList, sortOrder, sortBy)

  const handleAdd = (params: {
    cardWidth: number
    startDate: number
    endDate?: number | null
  }): void => {
    addCardWidthConfig({
      clinic_id: clinic.clinic_id,
      config: { cardWidth: params.cardWidth },
      startDate: params.startDate,
      endDate: params.endDate
    })
    setIsOpenAddRecord(false)
  }

  const handleUpdate = (
    configId: string,
    params: {
      cardWidth: number
      startDate: number
      endDate?: number | null
    }
  ): void => {
    updateCardWidthConfig({
      clinic_id: clinic.clinic_id,
      config_id: configId,
      config: { cardWidth: params.cardWidth },
      startDate: params.startDate,
      endDate: params.endDate
    })
    setEditing('')
  }

  const toggleRow = (id: string): void => {
    setSelections((current) =>
      current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    )
  }

  const toggleAll = (): void => {
    setSelections((current) =>
      current.length === cardWidthList.length
        ? []
        : cardWidthList.map((item) => item.config_id)
    )
  }

  const handleSort = (newSortBy: 'created_at' | 'updated_at'): void => {
    if (sortBy === newSortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(newSortBy)
      setSortOrder('desc')
    }
  }

  return {
    sortOrder,
    sortBy,
    selections,
    editing,
    isOpenAddRecord,
    sortedCardWidthList,
    setIsOpenAddRecord,
    setEditing,
    handleAdd,
    handleUpdate,
    toggleRow,
    toggleAll,
    handleSort
  }
} 