// FIXME: 各関数にテストを追加

import { addMinutes, differenceInMinutes, fromUnixTime, getHours, getMinutes, getUnixTime, setHours, setMinutes } from "date-fns";
import { type ClinicTimeType } from "@/components/Common//entities";
import { CheckIcon } from '@heroicons/react/solid'
import { Listbox } from '@headlessui/react'

const unixTimestamp = 1649128800 // 2022-04-04 08:00:00 (UTC)
const date = fromUnixTime(unixTimestamp)


// 選択された予約の開始時間から、診療終了時間までの15分刻みで選択要素を生成する
// FIXME: clinic_timeを渡しているけどend.hoursとend.minutesだけでよさそう
export function getOptionElements (
    treatmentStartTime: number,
    clinic_time: ClinicTimeType,
    minTreatmentTime: number,
    stepsMinutes: number
): JSX.Element {
    const treatmentStartDate = fromUnixTime(treatmentStartTime);
    const endOfShiftDate = getEndOfShiftDate(treatmentStartDate, clinic_time);

    const maxTreatmentMinutes = differenceInMinutes(endOfShiftDate, treatmentStartDate)
    const treatmentMinutes: number[] = getTreatmentMinutes(maxTreatmentMinutes, minTreatmentTime, stepsMinutes)

    const treatmentTimes = getTreatmentTimes(treatmentStartDate, treatmentMinutes);
    const optionElements = createOptionElements(treatmentTimes);

    return optionElements;
}

export function getTreatmentMinutes (maxTreatmentTime: number, minTreatmentTime: number, stepsMinutes: number): number[] {
    const treatmentMinutes: number[] = []
    for (let i = minTreatmentTime; i <= maxTreatmentTime; i += stepsMinutes) {
        treatmentMinutes.push(i)
    }
    return treatmentMinutes;
}

export function getEndOfShiftDate (
    treatmentStartDate: Date,
    clinic_time: ClinicTimeType
): Date {
    const settedHoursDate = setHours(treatmentStartDate, clinic_time.endTime.hours);
    const endOfShiftDate = setMinutes(settedHoursDate, clinic_time.endTime.minutes);

    return endOfShiftDate;
}

export function getTreatmentTimes (
    appointTreatmentStartDate: Date,
    treatmentMinutes: number[]
): number[] {
    const treatmentTimes = treatmentMinutes.map(minutes => {
        const _treatmentTime = addMinutes(appointTreatmentStartDate, minutes);
        const treatmentTime = getUnixTime(_treatmentTime);
        return treatmentTime;
    })
    const sortedTreatmentTimes = treatmentTimes.sort((a, b) => a - b);
    return sortedTreatmentTimes;
}

export function createOptionElements (
    treatmentTimes: number[]
): JSX.Element {
    return (
        <>
            {treatmentTimes.map((treatmentTime, index) => {
                return (
                    <option className="font-normal text-gray-800 text-l" value={treatmentTime} key={index}>
                        {`${getHours(fromUnixTime(treatmentTime))}時${getMinutes(fromUnixTime(treatmentTime))}分`}
                    </option>
                )
            })}
        </>
    )
}