import { useUpDateMenuMutation } from '../../../../../../.graphql/types';
import { Dialog, DialogContent } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { emptyCategory, emptyMenu } from '../../common/emptyData';
import type { CategoryType, MenuType } from '../../common/type';
import { medicalRole, medicalStatus } from '../../common/medicalMenu';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client';
import { getUnixTime, startOfDay } from 'date-fns';
import { getTreatmentMinutes } from '@/components/Common/utils/common/addData';

interface EditMenuModalType {
    categoryList: CategoryType[],
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

function clearState(
    setCurrentMenu: React.Dispatch<React.SetStateAction<MenuType>>,
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
) {
    setCurrentMenu(emptyMenu);
    setEditMode(false);
}

const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
    return config?.__typename === "CardWidthConfig";
};

export const useEditMenuModal = () => {
    const graphqlClient = useGraphqlClient()
    const clinic_id = useContext(ClinicContext).clinic_id;
  
    // clinic設定の取得
    const { data: cardWidthList } = useGetLatestClinicConfigQuery(graphqlClient, {
        clinic_id,
        current_date: getUnixTime(startOfDay(new Date())),
        config_type: 'card_width',
    })
  
    const configData = cardWidthList?.getLatestClinicConfig?.config ?? null;
    const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 30;
    const maxTreatmentMinutes = 180;
    const minTreatmentTime = cardWidth;
  
    const treatmentTimes = getTreatmentMinutes(maxTreatmentMinutes, minTreatmentTime, cardWidth);
  

    const [currentMenu, setCurrentMenu] = useState(emptyMenu);

    const EditMenuModal: React.FC<EditMenuModalType> = ({
        categoryList,
        setEditMode
    }) => {

        const queryClient = useQueryClient()
        const methods = useForm({
            defaultValues: {
                SDK_ID: currentMenu.SDK_ID,
                amount: currentMenu.amount,
                courponId: currentMenu.courponId,
                role: currentMenu.role,
                name: currentMenu.name,
                abbreviation: currentMenu.abbreviation,
                subCategoryId: currentMenu.subCategoryId,
                parts: currentMenu.parts,
                status: currentMenu.status,
                time: currentMenu.time || treatmentTimes[0],
                type: currentMenu.type,
                isDelete: currentMenu.isDelete,
                index: currentMenu.index
            }
        })

        const { watch, register } = methods;
        const [categoryOptions, setCategoryOptions] = useState([emptyCategory]);

        useEffect(() => {
            if (watch('type')?.length !== 0) {
                const _categoryOptions = categoryList.filter(category => {
                    return category.mainCategory === watch('type');
                })
                setCategoryOptions(_categoryOptions);
            }
        }, [watch('type')])

        function onSubmit(data: MenuType) {
            const time = Number(data.time);
            if (isNaN(time) || time <= 0) {
                alert('診療時間は有効な数値を入力してください');
                return;
            }
            
            const submissionData = {
                ...data,
                time: time,
                clinic_id: clinic_id
            };
            
            updateMenu.mutate(submissionData);
        }
        const graphqlClient = useGraphqlClient()
        const updateMenu = useUpDateMenuMutation(graphqlClient, {
            onSettled: () => {
                queryClient.invalidateQueries('listMenu').catch(() => console.log('on Settled'))
            },
            onSuccess: () => {
                setEditMode(false)
            },
            onError: error => {
                console.error('メニューの更新に失敗しました:', error);
                alert('メニューの更新に失敗しました');
                setEditMode(false)
            },
            retry: 3
        })

        return (
            <Dialog
                transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                onClose={() => clearState(setCurrentMenu, setEditMode)}
                open={true}
                PaperProps={{
                    style: {
                        maxWidth: 'none',
                        width: '64%',
                        transition: 'all 0',
                        zIndex: 9999
                    }
                }}
            >
                <DialogContent
                    dividers
                    style={{ padding: '0px 0px 0px 0px', maxWidth: 'none', maxHeight: 'none', height: '86vh', position: 'relative' }}
                >
                    <FormProvider {...methods}>
                        <form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            className="flex flex-col items-center justify-center h-full gap-8"
                        >
                            <p className="w-full inline-flex justify-center items-center py-3 text-xl tracking-wide">
                                <span className="text-red-500">診療メニュー</span>を編集してください。
                            </p>
                            <div className="flex flex-wrap gap-y-2 justify-start items-center">
                                <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label className="block text-xs font-medium text-gray-900">
                                        メニュー名
                                        <div className="text-xl border border-gray-300 rounded">
                                            <input
                                                type="text"
                                                {...register('name', { required: true })}
                                                className="px-4 h-10 inline-flex items-center block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                                placeholder="診療カテゴリ名"
                                            />
                                        </div>
                                    </label>
                                </div>
                                <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label className="block text-xs font-medium text-gray-900">
                                        略称
                                        <div className="text-xl border border-gray-300 rounded">
                                            <input
                                                type="text"
                                                {...register('abbreviation')}
                                                className="px-4 h-10 inline-flex items-center block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                                placeholder="略称"
                                            />
                                        </div>
                                    </label>
                                </div>
                                <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label className="block text-xs font-medium text-gray-900">
                                        タイプ
                                        <div className="text-xl border border-gray-300 rounded">
                                            <select
                                                {...register('type', { required: true })}
                                                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                            >
                                                <option value="医科" selected={watch('type') === "医科"}>医科</option>
                                                <option value="歯科" selected={watch('type') === "歯科"}>歯科</option>
                                            </select>
                                        </div>
                                    </label>
                                </div>
                                <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label className="block text-xs font-medium text-gray-900">
                                        サブカテゴリ
                                        <div className="text-xl border border-gray-300 rounded">
                                            <select
                                                {...register('subCategoryId', { required: true })}
                                                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                            >
                                                {categoryOptions.map(category => {
                                                    return (
                                                        <option value={category.id} key={category.id} selected={watch('subCategoryId') === category.id}>
                                                            {category.name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </label>
                                </div>
                                <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label className="block text-xs font-medium text-gray-900">
                                        診療時間
                                        <div className="text-xl border border-gray-300 rounded">
                                            <select
                                                {...register('time', { required: true })}
                                                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                            >
                                                {treatmentTimes.map((time: number, index: number) => (
                                                    <option value={time} key={index}>
                                                        {time}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </label>
                                </div>
                                <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label className="block text-xs font-medium text-gray-900">
                                        役割
                                        <div className="text-xl border border-gray-300 rounded">
                                            <select
                                                {...register('role')}
                                                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                            >
                                                {medicalRole.map((role: string, index: number) => (
                                                    <option value={role} key={index}>
                                                        {role}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </label>
                                </div>
                                <div className="w-1/2 rounded-md px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label className="block text-xs font-medium text-gray-900">
                                        状態
                                        <div className="text-xl border border-gray-300 rounded">
                                            <select
                                                {...register('status')}
                                                className="px-4 inline-flex items-center h-10 block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0"
                                            >
                                                {medicalStatus.map((state: string, index: number) => (
                                                    <option value={state} key={index}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="flex justify-end gap-4">
                                <input type="submit" value="登録" className="w-36 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 py-2 text-sm font-medium text-white shadow-sm" />
                                <p onClick={() => { clearState(setCurrentMenu, setEditMode) }} className="w-36 inline-flex justify-center items-center rounded-md border border-transparent bg-red-400 py-2 text-sm font-medium text-white shadow-sm">キャンセル</p>
                            </div>
                        </form>
                    </FormProvider>
                </DialogContent>
            </Dialog >
        )
    }

    return {
        EditMenuModal,
        setCurrentMenu
    }
}
