import { type FC } from 'react'
import { Box } from '@mantine/core'
import { type Clinic, type ClinicConfig } from '@/_graphql/graphql-client'
import { UpsertCardWidthForm } from './UpsertCardWidthForm'
import { CardWidthTableHeader } from './CardWidthTableHeader'
import { CardWidthTableRow } from './CardWidthTableRow'
import { useCardWidthPresenter } from '../hooks/useCardWidthPresenter'
import { useAuth0 } from '@auth0/auth0-react'

const VITE_AUTH0_APP_METADATA = import.meta.env.VITE_AUTH0_APP_METADATA

interface CardWidthPresenterProps {
  clinic: Clinic
  cardWidthList: ClinicConfig[]
  deleteClinicConfig: (params: {
    clinic_id: string
    config_id: string
    config_type: string
  }) => void
  addCardWidthConfig: (params: {
    clinic_id: string
    config: { cardWidth: number }
    startDate: number
    endDate?: number | null
  }) => void
  updateCardWidthConfig: (params: {
    clinic_id: string
    config_id: string
    config: { cardWidth: number }
    startDate: number
    endDate?: number | null
  }) => void
}

export const CardWidthPresenter: FC<CardWidthPresenterProps> = ({
  clinic,
  cardWidthList,
  deleteClinicConfig,
  addCardWidthConfig,
  updateCardWidthConfig
}): JSX.Element => {
  const {
    sortOrder,
    sortBy,
    selections,
    editing,
    isOpenAddRecord,
    sortedCardWidthList,
    setIsOpenAddRecord,
    setEditing,
    handleAdd,
    handleUpdate,
    toggleRow,
    toggleAll,
    handleSort
  } = useCardWidthPresenter({
    clinic,
    cardWidthList,
    addCardWidthConfig,
    updateCardWidthConfig
  })

  const { user } = useAuth0()

  const role = user?.[VITE_AUTH0_APP_METADATA]?.role

  return (
    <Box>
      <table className="w-full text-sm text-left text-gray-500">
        <CardWidthTableHeader
          sortBy={sortBy}
          sortOrder={sortOrder}
          handleSort={handleSort}
          isOpenAddRecord={isOpenAddRecord}
          setIsOpenAddRecord={setIsOpenAddRecord}
          selections={selections}
          cardWidthList={cardWidthList}
          toggleAll={toggleAll}
          isAdmin={role === 'admin'}
        />
        <tbody className="text-xs">
          {isOpenAddRecord && (
            <tr>
              <td colSpan={7}>
                <UpsertCardWidthForm
                  onSubmit={handleAdd}
                  onCancel={(): void => {
                    setIsOpenAddRecord(false)
                  }}
                />
              </td>
            </tr>
          )}
          {sortedCardWidthList.map((config) => (
            <CardWidthTableRow
              key={config.config_id}
              config={config}
              editing={editing}
              selections={selections}
              clinicId={clinic.clinic_id}
              toggleRow={toggleRow}
              setEditing={setEditing}
              handleUpdate={handleUpdate}
              deleteClinicConfig={deleteClinicConfig}
              isAdmin={role === 'admin'}
            />
          ))}
        </tbody>
      </table>
    </Box>
  )
} 