import "allotment/dist/style.css";
import { useContext, useRef } from "react";
import { Allotment, type AllotmentHandle } from "allotment"
import { useResetPaneSize } from "@/components/Main/hooks/useResetPaneReset";
import { AddAppointViews } from "@/components/Reservation/components/ModalContents/AddAppointViews";
import { PatientsListViews } from "@/components/Patient/components/views/PatientsListViews";
import { AppointmentListByPatientsViews } from "@/components/Appointment";
import { AppointBlockViews } from "@/components/Block";
import { type TopPaneType } from "@/components/Main/entities";
import { useAppoint_service } from "@/domain/Appointment/services/hooks";
import { SearchType } from "@/components/Common/entities";
import { PatientsEditViews } from "@/components/PatientInfo/components/views/PatientsEditViews";
import { usePatientList_service } from "@/domain";
import { ClinicContext } from "@/App";
import { ErrorBoundary } from "@/components/Common/components";
import { SetDateContext } from "@/components/Main/provider/MainProvider";
import { AppointmentListViews } from "@/components/Appointment/components/AppointmentList/views/AppointmentListViews";
import { useBlock_service } from "@/domain/Block/services/hooks";
import { useAppointV2_service } from "@/domain/Appointment/services/hooks/Query/useAppoint_service";
import { emptyAppoint, emptyAppointV2 } from "@/components/Common/utils/common/emptyData";
import { stepButtonClasses } from "@mui/material";
import { useGetLatestClinicConfigQuery, useListCardWidthConfigQuery } from "@/_graphql/graphql-client";
import { getUnixTime, startOfDay } from "date-fns";

const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
    return config?.__typename === "CardWidthConfig";
};

export const TopPane: React.FC<TopPaneType> = ({
    graphqlClient,
    // オペレーション
    operation,
    // URL--------------------------
    search,
    pathname,
    // 各種パラメータ
    patientId,
    blockId,
    startTime,
    endTime,
    facilityId,
    appointId,
}) => {

    // MEMO:サイズをリセットするためのref
    const topSubPanesRef = useRef<AllotmentHandle>(null);
    useResetPaneSize(topSubPanesRef)

    // 選択日付
    const { nowDate } = useContext(SetDateContext)


    // ページ判定
    const isAddAppoint = pathname.endsWith('/add-appoint')
    const isPatientList = pathname.endsWith('/patients-list')
    const isPatientInfo = pathname.endsWith('/patient-info')
    const isAppointList = pathname.endsWith('/appoints-list') && !search?.['block-id']
    const isAppointInfo = pathname.includes('/appoint-info') && !search?.['block-id']
    // const isAppointEdit = pathname.endsWith('/edit') && !Boolean(search?.['block-id'])

    // コンポーネント判定(TopPane)
    const isAddBlockViews = (search?.['block-id'] !== undefined)
    const clinic = useContext(ClinicContext)

    // 予約
    const _appoint = useAppointV2_service(graphqlClient, { clinic_id: clinic.clinic_id, patient_id: patientId, startTime }, { enabled: pathname.endsWith('/appoint-info'), retry: 1 })
    const appointReq = _appoint.req;
    const appoint = _appoint.data;

    // ブロック
    const _block = useBlock_service(
        graphqlClient,
        { id: blockId || '', startTime },
        { enabled: search?.['block-id'] !== undefined, retry: 1 }
    );
    const blockReq = _block.req;
    const block = _block.data;

    // clinic設定の取得
    const { data: cardWidthList, refetch } = useGetLatestClinicConfigQuery(graphqlClient, {
        clinic_id: clinic.clinic_id,
        current_date: getUnixTime(startOfDay(nowDate)),
        config_type: 'card_width',
    })

    const configData = cardWidthList?.getLatestClinicConfig?.config ?? null;
    const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 15;
    console.log("window", cardWidth);
    const minTreatmentTime = cardWidth;
    const stepsMinutes = cardWidth;


    // FIXME: 各PANEがpropsを受け取っていないので修正する
    return (
        <Allotment ref={topSubPanesRef} defaultSizes={[window.innerWidth / 10 * 6, window.innerWidth / 10 * 4]} className="p-4 pb-0" vertical={false}>
            {/* MEMO:URLのパラメータによって表示するコンポーネントを変更 */}

            {/* 患者一覧 */}
            <Allotment.Pane visible={isPatientList}>
                <ErrorBoundary>
                    {isPatientList ? <PatientsListViews /> : null}
                </ErrorBoundary>
            </Allotment.Pane>

            {/* 患者詳細 */}
            <Allotment.Pane visible={isPatientInfo} preferredSize={'65%'}>
                <ErrorBoundary>
                    {isPatientInfo &&
                        <PatientsEditViews
                            graphqlClient={graphqlClient}
                            patientId={patientId}
                        />
                    }
                </ErrorBoundary>
            </Allotment.Pane>
            <Allotment.Pane visible={isPatientInfo}>
                <ErrorBoundary>
                    {isPatientInfo &&
                        <AppointmentListByPatientsViews />
                    }
                </ErrorBoundary>
            </Allotment.Pane>

            {/* 予約一覧 */}
            <Allotment.Pane visible={isAppointList}>
                <ErrorBoundary>
                    <AppointmentListViews />
                </ErrorBoundary>
            </Allotment.Pane>

            {/* 予約詳細 */}
            <Allotment.Pane visible={isAppointInfo}>
                {
                    appointReq.isLoading &&
                    <div className="flex h-full justify-center items-center">
                        <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
                    </div>
                }
                {
                    <ErrorBoundary>
                        <AddAppointViews
                            graphqlClient={graphqlClient}
                            nowDate={nowDate}

                            // URLパラメータ
                            search={search}
                            pathname={pathname}
                            patientId={patientId}
                            startTime={startTime}
                            endTime={endTime}
                            facilityId={facilityId}
                            appointId={appointId}
                            // appoint
                            appoint={appoint || emptyAppointV2}
                            req={appointReq}
                            // 新規 or 編集
                            operation={operation}
                            minTreatmentTime={minTreatmentTime}
                            stepsMinutes={stepsMinutes}
                        />
                    </ErrorBoundary>
                }
                {
                    appointReq.status === 'error' && <p className="flex justify-center items-center h-full text-red-400">予約情報の取得に失敗しました</p>
                }
            </Allotment.Pane>
            <Allotment.Pane visible={isAppointInfo}>
                <ErrorBoundary>
                    <AppointmentListByPatientsViews />
                </ErrorBoundary>
            </Allotment.Pane>

            {/* 予約追加コンポーネント */}
            <Allotment.Pane visible={isAddAppoint && !isAddBlockViews} snap preferredSize={'65%'}>
                <ErrorBoundary>
                    {
                        <AddAppointViews
                            graphqlClient={graphqlClient}
                            nowDate={nowDate}

                            // URLパラメータ
                            search={search}
                            pathname={pathname}
                            patientId={patientId}
                            startTime={startTime}
                            endTime={endTime}
                            facilityId={facilityId}
                            appointId={appointId}
                            // appoint
                            appoint={appoint || emptyAppointV2}
                            req={appointReq}
                            // 新規 or 編集
                            operation={operation}
                            minTreatmentTime={minTreatmentTime}
                            stepsMinutes={stepsMinutes}
                        />
                    }
                </ErrorBoundary>
            </Allotment.Pane>

            {/* ブロック追加コンポーネント */}
            <Allotment.Pane visible={isAddAppoint || isAddBlockViews}>
                <ErrorBoundary>
                    {
                        blockReq.status === 'loading' &&
                        <div className="flex h-full justify-center items-center">
                            <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
                        </div>
                    }
                    {
                        <AppointBlockViews
                            blockId={blockId}
                            startTime={startTime}
                            endTime={endTime}
                            facilityId={facilityId}
                            operation={operation}
                            block={block}
                            req={blockReq}
                            minTreatmentTime={minTreatmentTime}
                            stepsMinutes={stepsMinutes}
                        />
                    }
                </ErrorBoundary>
            </Allotment.Pane>

        </Allotment>
    )
}