import { uuid4 } from '@sentry/utils'
import { FacilityType } from '@/components/Facility/entities'
import { getUnixTime, isWithinInterval } from 'date-fns'
import { ClinicTimeConfigType } from '@/components/ClinicSettings/entities'
import { emptyClinicTimeConfig } from '@/components/Common/utils/common/emptyData'

// コードレビュー:
// 2. `getTimes`関数内での`<div>`のスタイルは、CSSクラスとして外部化することを検討してください。これにより、スタイルの再利用と管理が容易になります。
// 3. `getRoopNumber`関数の名前が少しわかりにくいです。より具体的な名前に変更することを検討してください。
// 4. `filterFacilitiesByFloorId`関数内で、`floorId`の値に基づいて異なるフィルタリングを行っていますが、これをより簡潔にする方法を検討してください。
// 5. 全体的に、関数の命名やコメントを見直して、より具体的でわかりやすいものにすることを検討してください。

// 定数定義
// 1時間を秒で表現
export const hourTimeStamp: number = 60 * 60
// シフトの開始時間
export const shiftStartTime: number = 8
// シフトの終了時間
export const shiftEndTime: number = 21
// 15分を秒で表現
export const quarterTimeStamp: number = hourTimeStamp / 4

// シフトの時間帯を表示する関数
export function getTimes(shiftStartTime: number, shiftEndTime: number, shiftEndMinutes: number): JSX.Element {
  const items = []
  for (let i = shiftStartTime; i <= shiftEndTime; i++) {
    // シフト終了時間で終了分が0の場合
    if (i === shiftEndTime && shiftEndMinutes === 0) {
      items.push(<div style={{ height: '0px', transform: 'translateY(-1.5rem)' }}>{i}:00</div>)
    } else {
      items.push(<div style={{ height: '100px' }}>{i}:00</div>)
      items.push(<div style={{ height: '100px' }} className="text-sm">:30</div>)
    }
  }
  return (
    <div key={uuid4()} className='w-[5%] text-right'>
      {items}
    </div>
  )
}

/**
 * 現在の時間、現在の15分間隔のインデックス、およびシフト開始時間に基づいてループ番号とタイムスタンプを計算します。
 * 
 * @param {number} currentHour - 処理中の現在の時間。
 * @param {number} currentQuarterIndex - 現在の15分間隔のインデックス（0から3の範囲）。
 * @param {number} shiftStartHour - シフトが開始する時間。
 * @param {number} nowDate - 現在の日付のUNIXタイムスタンプ。
 * @param {number} hourTimeStamp - 1時間のタイムスタンプ。
 * @param {number} oneHourSlotLength - 1時間のスロットの長さ。
 * @returns {object} - 計算されたループ番号とタイムスタンプ。
 */
export function calculateCurrentSlotTimestamp(
  currentHour: number,
  currentQuarterIndex: number,
  shiftStartHour: number,
  nowDate: number,
  hourTimeStamp: number,
  oneHourSlotLength: number,
  intervalMinute: number,
): number {
  // 現在の時間がシフト開始時間と同じ場合、
  // ループ番号は現在のクォーターインデックスと同じ
  const roopNumber = currentHour === shiftStartHour
    ? currentQuarterIndex
    // それ以外の場合、現在の時間とシフト開始時間との差に基づいてループ番号を計算
    // 差を4倍して（1時間には4つのクォーターがあるため）、現在のクォーターインデックスを加算
    : oneHourSlotLength * (currentHour - shiftStartHour) + currentQuarterIndex;

  const timestamp = nowDate + hourTimeStamp * shiftStartHour + roopNumber * (hourTimeStamp / (60 / intervalMinute));

  return timestamp;
}

// 現在のタイムスタンプを計算して返す関数
export function getCurrentTimestamp(nowDate: number, shiftStartHours: number, hourTimeStamp: number, currentRoopNumber: number): number {
  return nowDate + hourTimeStamp * shiftStartHours + currentRoopNumber * (hourTimeStamp / 4)
}


// floorIdに基づいて施設リストをフィルタリングする関数
export function filterFacilitiesByFloorId(facilityList: FacilityType[], floorId: string | undefined): FacilityType[] {
  if (floorId === undefined || floorId === '') {
    return facilityList
  }
  if (floorId === 'unassigned') {
    return facilityList.filter(facility => facility.floor_id === undefined || facility.floor_id === '')
  }
  return facilityList.filter(facility => facility?.floor_id === floorId)
}

// 有効期間内かどうかを判定する関数
export function isWithinValidityPeriod(
  currentTimestamp: number,
  startTimestamp: number,
  endTimestamp: number
): boolean {
  const currentDate = new Date(currentTimestamp);
  const startDate = new Date(startTimestamp);
  const endDate = new Date(endTimestamp);
  return isWithinInterval(currentDate, { start: startDate, end: endDate });
}

// 有効期間中の診療時間設定を取得する関数
export function getActiveClinicTimeConfig(
  nowDate: Date,
  clinicTimeList: ClinicTimeConfigType[],
) {
  const currentTimestamp = getUnixTime(nowDate)
  const sortedTimeList = clinicTimeList.sort((a: ClinicTimeConfigType, b: ClinicTimeConfigType) => {
    return b.startDate - a.startDate
  })
  const activeClinicTime = sortedTimeList.find((clinicTime: ClinicTimeConfigType) => {
    return clinicTime.endDate === null ? isWithinValidityPeriod(currentTimestamp, clinicTime.startDate, clinicTime.endDate) : clinicTime.startDate <= currentTimestamp
  })
  return activeClinicTime || emptyClinicTimeConfig
}