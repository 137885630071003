import { $TimeSlot } from './$TimeSlot';
import { useContext } from 'react';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client';
import { getUnixTime, startOfDay } from 'date-fns';

export interface TimesProps {
    shiftStartTime: number;
    shiftEndTime: number;
    shiftEndMinutes: number;
}

export function calculateTimeSlots(shiftStartTime: number, shiftEndTime: number, cardWidth: number): number[] {
    // シフトの開始時間から終了時間までの合計時間を計算し、
    // cardWidthに基づいてスロット数を計算
    const totalMinutes = (shiftEndTime - shiftStartTime) * 60;
    const numberOfTimeSlot = Math.ceil(totalMinutes / cardWidth);
    return Array.from({ length: numberOfTimeSlot });
}

const style = 'w-[5%] text-right min-w-[45px]';

const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
    return config?.__typename === "CardWidthConfig";
};

export const $TimeBar: React.FC<TimesProps> = ({ shiftStartTime, shiftEndTime, shiftEndMinutes }) => {
    const graphqlClient = useGraphqlClient();
    const clinicId = useContext(ClinicContext).clinic_id;

    // clinic設定の取得
    const { data: cardWidthList } = useGetLatestClinicConfigQuery(graphqlClient, {
        clinic_id: clinicId,
        current_date: getUnixTime(startOfDay(new Date())),
        config_type: 'card_width',
    });

    const configData = cardWidthList?.getLatestClinicConfig?.config ?? null;
    const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 15;
    const timeSlots = calculateTimeSlots(shiftStartTime, shiftEndTime, cardWidth);

    return (
        <div className={style}>
            {
                timeSlots.map((_, index) => {
                    const totalMinutes = index * cardWidth;
                    const hour = shiftStartTime + Math.floor(totalMinutes / 60);
                    const minutes = totalMinutes % 60;

                    // 判定: シフトの終了分が0
                    const hasNoEndMinutes = shiftEndMinutes === 0;
                    // 判定: 現在時間がシフトの終了時間
                    const isEndOfShift = hour === shiftEndTime;
                    // 判定: シフトの終了時間 && 終了分が0 && 現在の時間が最後のスロットかチェック
                    const isEndOfShiftWithoutMinutes = isEndOfShift && hasNoEndMinutes && minutes >= 45;

                    return (
                        <$TimeSlot
                            key={index}
                            hour={hour}
                            min={minutes.toString().padStart(2, '0')}
                            isEndOfShift={isEndOfShift}
                            isEndOfShiftWithoutMinutes={isEndOfShiftWithoutMinutes}
                        />
                    );
                })
            }
        </div>
    );
};