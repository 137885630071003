import { type FC } from 'react'
import { Button } from '@mantine/core'
import { type ClinicConfig } from '@/_graphql/graphql-client'

// スタイル
const deleteBtnCss: string = 'cursor-pointer leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-red-700 hover:bg-red-900'
const disableBtnCss: string = 'leading-8 mr-2 text-gray-100 text-sm font-medium h-8 w-24 text-center rounded-2xl bg-gray-700 hover:bg-gray-900'

interface CardWidthTableHeaderProps {
  sortBy: 'created_at' | 'updated_at'
  sortOrder: 'asc' | 'desc'
  handleSort: (newSortBy: 'created_at' | 'updated_at') => void
  isOpenAddRecord: boolean
  setIsOpenAddRecord: (isOpen: boolean) => void
  selections: string[]
  cardWidthList: ClinicConfig[]
  toggleAll: () => void
  isAdmin: boolean
}

export const CardWidthTableHeader: FC<CardWidthTableHeaderProps> = ({
  sortBy,
  sortOrder,
  handleSort,
  isOpenAddRecord,
  setIsOpenAddRecord,
  selections,
  cardWidthList,
  toggleAll,
  isAdmin,
}): JSX.Element => (
  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th className="w-4 p-4">
        <input
          type="checkbox"
          className="cursor-pointer"
          onChange={toggleAll}
          checked={selections.length === cardWidthList.length}
        />
      </th>
      <th>予約枠(分)</th>
      <th>開始日</th>
      <th>終了日</th>
      <th onClick={(): void => handleSort('created_at')} className="cursor-pointer">
        作成日 {sortBy === 'created_at' && (sortOrder === 'asc' ? '▲' : '▼')}
      </th>
      <th onClick={(): void => handleSort('updated_at')} className="cursor-pointer">
        更新日 {sortBy === 'updated_at' && (sortOrder === 'asc' ? '▲' : '▼')}
      </th>
      <th>
        <Button
          className={`${isOpenAddRecord ? deleteBtnCss : disableBtnCss} text-xs p-0`}
          onClick={(): void => setIsOpenAddRecord(!isOpenAddRecord)}
          disabled={!isAdmin}
        >
          新規追加
        </Button>
      </th>
    </tr>
  </thead>
) 