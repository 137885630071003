import { FormProvider } from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import { NavigateType, useLocation, useNavigate } from '@tanstack/react-location'
import { DeleteAppointType } from '@/components/Common'
import { AppointFormPresenter } from './AppointFormPresenter'
import { ReserveType } from '../../entities'
import { MutateContext } from '@/provider/common/MutateProvider'
import { ClinicContext, useGraphqlClient } from '@/App'
import { initNewAppoint, onSubmit } from './utils/AddAppoint'
import { type PatientsType, type listManegerType } from "@/components/Patient/components/util/common/type";
import { type CategoryType, type MenuType } from '@/components/Menu/entities'
import { type FacilityType } from '@/components/Facility/entities'
import { type UseQueryResult } from 'react-query'
import { Appoint, AppointV2, GetAppointByIdV2Query, useGetPatientNameForAddNewAppointQuery, type GetAppointQuery } from '@/_graphql/graphql-client'
import { useAppointHandlers } from '../../hooks/useAppointHandlers'
import { SubmitConfirmationDialog } from '@/components/Reservation/components/ModalContents/SubmitConfirmationDialog'
import { getUnixTime } from 'date-fns'
import { DeleteConfirmationDialog } from '@/components/Reservation/components/ModalContents/DeleteConfirmationDialog'
import { useInitialFormHook, useSetAppointData, useSetNewPatient } from '../../hooks'
import { AppointForm } from '../parts/AppointDetail'

interface AppointFormViewsType {
    newPatient: PatientsType
    nowDate: Date
    isBeforeDay: boolean
    appoint: AppointV2
    req: UseQueryResult<GetAppointByIdV2Query, unknown>
    operation: 'add' | 'reference' | 'edit' | 'copy' | 'update' | undefined
    patientId: string
    startTime: number
    endTime: number
    facilityId: string
    appointId: string
    search: any
    menuList: MenuType[]
    menuListIncludeDelete: MenuType[]
    categoryList: CategoryType[]
    facilityList: FacilityType[]
    manegerLists: listManegerType[]
    manegerListsIncludeDelete: listManegerType[]

    setOpenAddPatientModal: React.Dispatch<React.SetStateAction<boolean>>,
    openSearchPatientModal: boolean,
    setOpenSearchPatientModal: React.Dispatch<React.SetStateAction<boolean>>,

    minTreatmentTime: number,
    stepsMinutes: number,
}

function onDeleteAppoint(
    id: string,
    startTime: number,
    deleteAppoint: DeleteAppointType
) {
    const deleteMutateArgs = {
        id,
        startTime
    }
    deleteAppoint.mutate(deleteMutateArgs)
}

export const AppointFormViews: React.FC<AppointFormViewsType> = ({
    newPatient,
    appoint,
    operation,

    nowDate,
    isBeforeDay,

    patientId,
    startTime,
    endTime,

    facilityId,

    search,
    menuList,
    menuListIncludeDelete,
    categoryList,
    facilityList,
    manegerLists,
    manegerListsIncludeDelete,
    setOpenAddPatientModal,
    openSearchPatientModal,
    setOpenSearchPatientModal,

    minTreatmentTime,
    stepsMinutes,
}) => {

    const navigate = useNavigate()
    const methods = useInitialFormHook();

    const [isChecked, setIsChecked] = useState(false)
    const [openCheckUpdateDialog, setOpenCheckUpdateDialog] = useState(false);
    const [openMedicalCategoryModal, setOpenMedicalCategoryModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)


    // mutates
    const mutates = useContext(MutateContext);
    const { addAppoint, updateAppoint, updateAppointInfo, deleteAppoint } = mutates;

    const graphqlClient = useGraphqlClient()
    const clinic_id = useContext(ClinicContext).clinic_id;

    const selectedPatientId = methods.watch('appointData.id')
    const selectedPatientData = useGetPatientNameForAddNewAppointQuery(graphqlClient, { clinic_id, id: selectedPatientId }, { enabled: selectedPatientId !== '0' && selectedPatientId.length > 0 })
    const displayPatientName = selectedPatientData.data?.getPatientNameByIdV2?.displayName

    //新規患者追加時に自動選択処理
    useSetNewPatient(methods, newPatient)

    useEffect(() => {

        switch (operation) {
            case 'add':
                initNewAppoint(methods.watch('appointData'), methods.setValue, facilityId, startTime, endTime)
                break;

            case 'edit':
                //編集モード中の処理はシフト枠(ReservationShiftPresenter)が担保
                break;
            case 'update':
                methods.setValue('appointData.startTime', startTime)
                methods.setValue('appointData.endTime', endTime)
                methods.setValue('appointData.facilityId', facilityId)
                break
            default:
                break;
        }

    }, [startTime, facilityId])

    useEffect(() => {
        if (operation === 'reference' || operation === 'copy') {
            methods.setValue('appointData', appoint as Appoint)
            methods.setValue('appointData.endTime', endTime)
            methods.setValue('ReferenceAppointKey.baseId', appoint.id)
            methods.setValue('ReferenceAppointKey.baseStartTime', appoint.startTime)
        }
        if (operation === 'copy') {
            methods.setValue('appointData.appoint', '予約済み')
            methods.setValue('appointData.isLate', false)
        }

    }, [appoint])

    //submit後の処理を実行するカスタムフック
    useAppointHandlers(addAppoint, updateAppoint, updateAppointInfo, deleteAppoint, methods.reset)

    const formData = methods.watch('appointData')
    return (
        <>
            <FormProvider {...methods}>
                <AppointForm onSubmit={methods.handleSubmit(() => setOpenCheckUpdateDialog(true))}>
                    {
                        <AppointFormPresenter
                            nowDate={nowDate}

                            formData={formData}
                            watch={methods.watch}
                            register={methods.register}
                            methods={methods}
                            getValues={methods.getValues}
                            appoint={appoint}

                            operation={operation}
                            navigate={navigate as NavigateType}

                            startTime={startTime}
                            endTime={endTime}
                            patientId={patientId}
                            facilityId={facilityId}

                            menuList={menuList}
                            menuListIncludeDelete={menuListIncludeDelete}
                            categoryList={categoryList}
                            facilityList={facilityList}
                            manegerListsIncludeDelete={manegerListsIncludeDelete}

                            displayPatientName={displayPatientName || '患者名*'}
                            patientRemarks={appoint.patientRemarks || ''}

                            setOpenAddPatientModal={setOpenAddPatientModal}
                            openSearchPatientModal={openSearchPatientModal}
                            setOpenSearchPatientModal={setOpenSearchPatientModal}
                            openCheckUpdateDialog={openCheckUpdateDialog}
                            setOpenCheckUpdateDialog={setOpenCheckUpdateDialog}
                            openMedicalCategoryModal={openMedicalCategoryModal}
                            setOpenMedicalCategoryModal={setOpenMedicalCategoryModal}

                            setIsOpenDeleteModal={() => setIsOpenDeleteModal(true)}
                            minTreatmentTime={minTreatmentTime}
                            stepsMinutes={stepsMinutes}
                        />
                    }

                    {/* 予約登録確認ダイアログ */}
                    {openCheckUpdateDialog &&
                        <SubmitConfirmationDialog
                            onSubmit={onSubmit}

                            isBeforeDay={isBeforeDay}

                            data={methods.watch()}
                            operation={operation}
                            mutates={mutates}
                            clinic_id={clinic_id}
                            menuList={menuList}
                            nowDate={nowDate}
                            navigate={navigate as NavigateType}
                            search={search}
                            setOpenCheckUpdateDialog={setOpenCheckUpdateDialog}

                            onClose={() => setOpenCheckUpdateDialog(false)}
                            manegerList={manegerLists}
                            isChecked={isChecked}
                            setIsChecked={() => setIsChecked(!isChecked)}
                            id={methods.watch('appointData.id')}
                            startTime={Number(methods.watch('appointData.startTime'))}
                            endTime={Number(methods.watch('appointData.endTime'))}
                            date={Number(methods.watch('appointData.date')) || getUnixTime(nowDate)}
                            facilityId={methods.watch('appointData.facilityId')}
                            staffIds={methods.watch('appointData.staffIds')}
                            appointId={methods.watch('appointData.appointId')}
                        />}

                    {/* 予約削除確認ダイアログ */}
                    {isOpenDeleteModal &&
                        <>
                            <DeleteConfirmationDialog
                                label={'予約'}
                                isChecked={isChecked}
                                setIsChecked={() => setIsChecked(!isChecked)}
                                onClose={() => setIsOpenDeleteModal(false)}
                                onDelete={() => {
                                    onDeleteAppoint(patientId, methods.watch('appointData.startTime'), deleteAppoint)
                                    navigate({ to: `/main/?calendar-date=${getUnixTime(nowDate)}` })
                                    setIsOpenDeleteModal(false)
                                }}
                            />
                        </>}
                </AppointForm>
            </FormProvider >
        </>
    )
}


