import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { GraphQLClient } from 'graphql-request'
import { useMediaQuery } from "@mantine/hooks";
import { emptyClinic } from "@/components/Common/utils/common/emptyData";
import { MutateProvider, RouteProvider } from "@/provider";
import { useLogin, useClinicSelection, useAuthToken, useRedirect } from '@/components/Common/hooks'
import { createContext, useContext } from "react";
import { ReactQueryProvider } from "./provider/common/ReactQueryProvider";
import FormModeProvider from "./components/Main/provider/FormModeProvider";
import { MainProvider } from "./components/Main";
import { Outlet } from "@tanstack/react-location";
import { FacilityGroupContextProvider } from "./domain/FacilityGroup/useFacilityGroup";
import { IsMobileContextProvider } from "./provider/common/IsMobileProvider";
import { BlockParamsContextProvider } from "./domain/Block/useBlockParams";
import { AppointParamsContextProvider } from './domain/Appointment/useAppointParams'
import { CommonParamsContextProvider } from "./domain/Common/useCommonParams";

const VITE_SENTRY_DNS = process.env.VITE_SENTRY_DNS ?? "";
const VITE_GRAPHQL_ENDPOINT = process.env.VITE_GRAPHQL_ENDPOINT ?? "";

if (VITE_SENTRY_DNS) {
  Sentry.init({
    dsn: VITE_SENTRY_DNS,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

// 予約・ブロックの編集モードを保持するContext

// clinet_id系 Context
export const ClinicContext = createContext(emptyClinic);
export const ClinicListContext = createContext([emptyClinic])

export const graphqlContext = createContext({
  graphqlClient: new GraphQLClient('', { headers: { Authorization: '' } })
})

export const useGraphqlClient = (): GraphQLClient => {
  const { graphqlClient } = useContext(graphqlContext)
  return graphqlClient
}

export function App (): JSX.Element {
  const matches = useMediaQuery('(max-width: 928px)')
  const { clinic_label, org_label } = getLabels(location.origin)

  // ログイン
  const { isAuthenticated } = useLogin();
  // ログイン成功 → 医院選択
  const { clinics, selectClinic, ClinicSelection } = useClinicSelection(clinic_label, org_label);
  // 医院選択完了 → リダイレクト
  const { isRedirected } = useRedirect(selectClinic)
  // リダイレクト完了 → トークン取得
  const { token } = useAuthToken(isRedirected);
  // トークン取得完了 → graphQLClientインスタンス作成
  const graphqlClient = new GraphQLClient(VITE_GRAPHQL_ENDPOINT, { headers: { Authorization: token } })

  return (
    <>
      {// 未リダイレクト&医院未選択ならマウント
        (!clinic_label && !selectClinic.clinic_id) && isAuthenticated &&
        <ClinicSelection matches={matches} clinics={clinics} />
      }

      {/* graphQLClientインスタンスの生成が完了するまでマウントしない */}
      {token ? (
        <graphqlContext.Provider value={{ graphqlClient }}>
          <ClinicContext.Provider value={{ ...emptyClinic, ...selectClinic }}>
            <ClinicListContext.Provider value={clinics}>
              <ReactQueryProvider>
                <MutateProvider graphqlClient={graphqlClient}>
                  <MainProvider>
                    <FormModeProvider>

                      <IsMobileContextProvider>
                        <RouteProvider>
                          <CommonParamsContextProvider>
                            <FacilityGroupContextProvider>
                              <AppointParamsContextProvider>
                                <BlockParamsContextProvider>
                                  <Outlet />
                                </BlockParamsContextProvider>
                              </AppointParamsContextProvider>
                            </FacilityGroupContextProvider>
                          </CommonParamsContextProvider>
                        </RouteProvider>
                      </IsMobileContextProvider>

                    </FormModeProvider>
                  </MainProvider>
                </MutateProvider>
              </ReactQueryProvider>
            </ClinicListContext.Provider>
          </ClinicContext.Provider>
        </graphqlContext.Provider>
      ) : null}
    </>
  );
}

// ラベル取得
function getLabels (origin: string) {
  let clinic_label = '';
  let org_label = '';

  // ドメインを分割（ラベル取得用）
  const originsArray = origin.replace('https://', '').split('.');

  // splitしたドメインがlength４ならラベル取得
  if (originsArray.length === 4) {
    clinic_label = originsArray[0]
    org_label = originsArray[1]
  }

  // localの場合は「.local」が加わるためlength5でラベル取得
  if (origin.includes('local')) {
    if (originsArray.length === 5) {
      clinic_label = originsArray[0]
      org_label = originsArray[1]
    }
  }

  return {
    clinic_label,
    org_label
  }
}