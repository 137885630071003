import { type FC, useContext } from 'react'
import { ClinicContext, useGraphqlClient } from '@/App'
import {
  useListCardWidthConfigQuery,
  useAddCardWidthConfigMutation,
  useUpdateCardWidthConfigMutation,
  useDeleteClinicConfigMutation,
  type Clinic,
  type ClinicConfig
} from '../../../../_graphql/graphql-client'
import { CardWidthPresenter } from '../presenter/CardWidthPresenter'
import { generateSortedConfigList } from '../../utils/libs/common'

export const CardWidthViews: FC = (): JSX.Element => {
  const clinicContext = useContext(ClinicContext)
  const clinic: Clinic = {
    org_id: clinicContext.org_id,
    clinic_id: clinicContext.clinic_id,
    clinic_label: clinicContext.clinic_label,
    clinic_name: clinicContext.clinic_name,
    org_label: clinicContext.org_label,
    created_at: 0,
    isDelete: false
  }
  const graphqlClient = useGraphqlClient()
  const { data: cardWidthList, refetch } = useListCardWidthConfigQuery(graphqlClient, {
    clinic_id: clinic.clinic_id
  })

  const sortedCardWidthList = generateSortedConfigList(
    cardWidthList?.listClinicConfig?.filter((config): config is ClinicConfig => config !== null) ?? [],
    'desc',
    'created_at'
  )

  const { mutate: deleteConfig } = useDeleteClinicConfigMutation(graphqlClient, {
    onSuccess: () => {
      void refetch()
    }
  })

  const { mutate: addCardWidth } = useAddCardWidthConfigMutation(graphqlClient, {
    onSuccess: () => {
      void refetch()
    }
  })

  const { mutate: updateCardWidth } = useUpdateCardWidthConfigMutation(graphqlClient, {
    onSuccess: () => {
      void refetch()
    }
  })

  const handleDelete = (params: {
    clinic_id: string
    config_id: string
    config_type: string
  }): void => {
    deleteConfig(params)
  }

  return (
    <>
      <CardWidthPresenter
        clinic={clinic as Clinic}
        cardWidthList={sortedCardWidthList}
        deleteClinicConfig={handleDelete}
        addCardWidthConfig={addCardWidth}
        updateCardWidthConfig={updateCardWidth}
      />
    </>
  )
} 