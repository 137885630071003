import { getOptionElements } from '@/components/Common/utils'
import { ClinicTimeType } from '@/components/Common/entities'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { MethodsContext } from '@/components/Main/provider/FormModeProvider'
import { useContext, useEffect, useState } from 'react'

interface SelectPresenterType {
    setSelected: React.Dispatch<React.SetStateAction<number>>
    registerType: 'appointData' | 'block'
    //FIXME:type定義
    register: any
    treatmentStartTime: number
    clinic_time: ClinicTimeType,
    setEndTime: any
    stepsMinutes: number
    minTreatmentTime: number
}

export const SelectPresenter: React.FC<SelectPresenterType> = ({
    registerType,
    register,
    treatmentStartTime,
    clinic_time,
    stepsMinutes,
    minTreatmentTime,
    setEndTime,
}) => {

    //startTime, endTimeが更新されるたびにgetOptionElementsを更新
    const [optionElements, setOptionElements] = useState(getOptionElements(treatmentStartTime, clinic_time, minTreatmentTime, stepsMinutes))

    useEffect(() => {
        setOptionElements(getOptionElements(treatmentStartTime, clinic_time, minTreatmentTime, stepsMinutes))
    }, [treatmentStartTime])

    return (
        <>
            {
                <div className={`flex justify-between items-end`}>
                    {/* 日時 */}
                    <div className='ml-4 flex items-end text-xs'>
                        <div className="flex items-end">
                            <select
                                {...register(`${registerType}.endTime`, { required: true })}
                                onChange={(e) => { setEndTime(Number(e.target.value)) }} // クエリ追加
                                className='py-1 rounded border-gray-50 text-gray-900 text-xs rounded bg-gray-200 text-right'
                            >
                                <option hidden>0</option>
                                {
                                    optionElements
                                }
                            </select>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

