import type { FacilityType } from "@/components/Facility/entities";
import { calculateCurrentSlotTimestamp, hourTimeStamp } from "@/components/Reservation/utils";
import { unixTimeFromNowDate } from "@/feature/phr-28/components/template/mastaRegistration/shiftRegistration/DailyCalendar/CalendarCommon";
import { QuarterSlot } from "../controller/QuarterSlot";
import { getEndTime } from "@/components/Common/utils";
import { addMinutes, differenceInMinutes, fromUnixTime, getUnixTime } from "date-fns";
import type { AddAppointSetValuesType, ReserveType } from "@/components/Reservation/entities";
import type { NavigateType } from "@/components/Common/entities";
import type { setAppointParamsType } from "@/domain/Appointment/useAppointParams";
import type { setBlockParamsType } from "@/domain/Block/useBlockParams";
import type { setCommonParamsType } from "@/domain/Common/useCommonParams";
import { emptyAppoint } from "@/feature/phr-28/components/common/emptyData";

export interface HourSlotComponentProps {
    // スロット関連
    intervalMinute: number;
    shiftStartHours: number;
    shiftStartMinutes: number;
    shiftEndHours: number;
    shiftEndMinutes: number;
    currentHour: number;
    isShiftStart: boolean;
    isShiftEnd: boolean;
    shiftStartQuarterIndex: number;
    shiftEndQuarterIndex: number;
    isHoliday: boolean;
    isBeforeDay: boolean;
    // 予約モード
    operation: "add" | "reference" | "edit" | "copy" | "update" | undefined;
    // 選択日付
    nowDate: Date;
    // 施設
    facility: FacilityType;
    // ブロックID
    blockId: string;
    startTime: number;
    endTime: number;
    appointId: string;
    // ペイン関連
    openPane: any;
    setIsPaneOpen: React.Dispatch<React.SetStateAction<boolean>> | null;
    subPanesRef: any;
    viewSizes: { width: number; height: number };
    // フォームメソッド
    watch: any;
    setValue: any;
    navigate: any;
    setCommonParams: setCommonParamsType;
    setBlockParams: setBlockParamsType;
    setAppointParams: setAppointParamsType;
    resetAppointId: () => void;
    resetBlockId: () => void;
    location: any;
}

const style = 'w-full flex flex-col justify-between';

// unitHeight: 予約カードの基本高さ
const unitHeight = 100;


// 1時間分のスロット
export const OneHourSlots: React.FC<HourSlotComponentProps> = (props) => {
    // 1時間分のスロットの高さ
    const oneHourSlotHeight = { height: `${unitHeight * 60 / props.intervalMinute}px` };

    const quarterSlots = generateSlots(props.intervalMinute);
    const oneHourSlotLength = quarterSlots.length;

    // スロット * ループ
    return (
        <div className={style} style={oneHourSlotHeight}>
            {quarterSlots.map((_, currentQuarterIndex) => {
                // QuarterSlotのタイムスタンプ
                const currentSlotTimestamp: number = calculateCurrentSlotTimestamp(
                    props.currentHour,
                    currentQuarterIndex,
                    props.shiftStartHours,
                    unixTimeFromNowDate(props.nowDate),
                    hourTimeStamp,
                    oneHourSlotLength,
                    props.intervalMinute
                );
                // 予約可能な時間枠でなければdisabled
                const isDisabled = checkIsDisabled(
                    props.isShiftStart,
                    currentQuarterIndex,
                    props.shiftStartQuarterIndex,
                    props.isShiftEnd,
                    props.shiftEndQuarterIndex
                );

                return (
                    <QuarterSlot
                        key={currentQuarterIndex}
                        isHourTime={currentQuarterIndex === 0}
                        isHalfTime={currentQuarterIndex === Math.floor(60 / (props.intervalMinute * 2))}
                        isHoliday={props.isHoliday}
                        isDisabled={isDisabled}
                        isBeforeDay={props.isBeforeDay}
                        onClickHandler={() => {
                            if (props.operation === 'copy' || props.operation === 'edit') {
                                const interval = props.endTime - props.startTime;
                                //intervalを分に直す
                                const intervalMinute = interval / 60;

                                if (props.blockId) {
                                    // startTimeが更新されていた時の処理
                                    props.navigate({ to: `update`, fromCurrent: true });
                                    props.setBlockParams(
                                        getUnixTime(props.nowDate),
                                        props.blockId,
                                        currentSlotTimestamp,
                                        getEndTime(currentSlotTimestamp, intervalMinute),
                                        props.facility.id
                                    );
                                    return;
                                }

                                // ここに追加
                                const navigateOption = props.operation === 'copy'
                                    ? { to: 'add-appoint', fromCurrent: false }
                                    : { to: 'update', fromCurrent: true };
                                const appoint = props.watch('appointData');
                                openPanel(props.setIsPaneOpen, props.openPane, props.subPanesRef, props.viewSizes);
                                props.navigate(navigateOption);
                                props.setAppointParams(
                                    getUnixTime(props.nowDate),
                                    appoint.id,
                                    currentSlotTimestamp,
                                    getEndTime(currentSlotTimestamp, intervalMinute),
                                    props.facility.id,
                                    props.appointId
                                );
                                props.setValue('appointData.startTime', currentSlotTimestamp);
                                props.setValue('appointData.endTime', getEndTime(currentSlotTimestamp, intervalMinute));
                                props.setValue('appointData.facilityId', props.facility.id);
                                return;
                            }

                            openPanel(props.setIsPaneOpen, props.openPane, props.subPanesRef, props.viewSizes);
                            handleNavigation(props.navigate);
                            props.resetAppointId();
                            props.resetBlockId();
                            props.setCommonParams(
                                getUnixTime(props.nowDate),
                                currentSlotTimestamp,
                                getEndTime(currentSlotTimestamp, props.intervalMinute),
                                props.facility.id
                            );
                            props.setValue('appointData', { ...emptyAppoint });
                            setAppointmentData(props.setValue, currentSlotTimestamp, props.facility.id, props.intervalMinute);
                        }}
                    />
                );
            })}
        </div>
    );
};

function generateSlots(cardWidth: number): any[] {
    const oneHourSlotLength = 60 / cardWidth;
    return Array.from({ length: oneHourSlotLength });
}

// パネルを開く関数
const openPanel = (
    setIsPaneOpen: React.Dispatch<React.SetStateAction<boolean>> | null,
    openPane: any,
    subPanesRef: any,
    viewSizes: { width: number; height: number }
) => {
    setIsPaneOpen && setIsPaneOpen(true);
    openPane(subPanesRef, 'top', viewSizes.height, viewSizes.width);
};

// 予約データを設定する関数
const setAppointmentData = (
    setValue: any,
    timestamp: number,
    facilityId: string,
    cardWidth: number
) => {
    setValue('appointData.id', '0');
    setValue('appointData.startTime', timestamp);
    setValue('appointData.endTime', getEndTime(timestamp, cardWidth));
    setValue('appointData.facilityId', facilityId);
};

// ナビゲーションを実行する関数
const handleNavigation = (navigate: any) => {
    navigate({ to: `/main/add-appoint` });
};

/**
 * 予約可能な時間枠かどうかを判定する関数。
 * 
 * @param {boolean} isShiftStart - シフトが開始されているかどうか。
 * @param {number} currentQuarterIndex - 現在の15分間隔のインデックス。
 * @param {number} shiftStartQuarterIndex - シフト開始の15分間隔のインデックス。
 * @param {boolean} isShiftEnd - シフトが終了しているかどうか。
 * @param {number} shiftEndQuarterIndex - シフト終了の15分間隔のインデックス。
 * @returns {boolean} - 予約可能な時間枠でなければtrue、それ以外はfalse。
 */
function checkIsDisabled(
    isShiftStart: boolean,
    currentQuarterIndex: number,
    shiftStartQuarterIndex: number,
    isShiftEnd: boolean,
    shiftEndQuarterIndex: number
): boolean {
    const isBeforeShiftStart = isShiftStart && (currentQuarterIndex < shiftStartQuarterIndex);
    const isAfterShiftEnd = isShiftEnd && ((currentQuarterIndex + 1) > shiftEndQuarterIndex);
    return isBeforeShiftStart || isAfterShiftEnd;
}

//ブロック編集
// 予約情報の初期値を計算するヘルパー関数
export function calculateInitialValue(
    baseAppointValue: ReserveType,
    startTime: number,
    facilityId: string
): ReserveType {
    const treatmentTime = differenceInMinutes(
        fromUnixTime(baseAppointValue.endTime),
        fromUnixTime(baseAppointValue.startTime)
    );
    const endDate = addMinutes(fromUnixTime(startTime), treatmentTime);
    const endTime = getUnixTime(endDate);

    return {
        ...baseAppointValue,
        facilityId,
        startTime,
        endTime
    };
}

function setInitialAppointValue(
    baseAppointValue: any,
    startTime: number,
    facilityId: string,
    setValue: any
) {
    // 予約情報の初期値を計算
    const initialValue = calculateInitialValue(baseAppointValue, startTime, facilityId);
    // 計算された初期値をセット
    setValue('appointData', initialValue);
    // 参照元予約のプライマリキーをセット
    setValue('ReferenceAppointKey.baseId', baseAppointValue.id);
    setValue('ReferenceAppointKey.baseStartTime', baseAppointValue.startTime);
}

// URLパラメータを設定するヘルパー関数
export function setURLParams(
    baseAppointValue: any,
    startTime: number,
    facilityId: string
): string {
    const initialValue = calculateInitialValue(baseAppointValue, startTime, facilityId);
    const params = new URLSearchParams(location.search);
    params.set('startTime', String(initialValue.startTime));
    params.set('endTime', String(initialValue.endTime));
    params.set('facilityId', facilityId);
    return `${location.pathname}/update?${params.toString()}`;
}

// 予約編集関数
export function handleEditOrCopy(
    appoint: ReserveType,
    setValue: AddAppointSetValuesType,
    facilityId: string,
    startTime: number,
    navigate: NavigateType) {
    // 参照元予約の取得
    const baseAppointValue = { ...appoint };

    // 予約情報の初期値を計算:セット
    setInitialAppointValue(baseAppointValue, startTime, facilityId, setValue);
    // URLパラメータを設定
    const updatedURL = setURLParams(baseAppointValue, startTime, facilityId);

    // urlを更新
    navigate({ to: updatedURL, fromCurrent: true });
}
