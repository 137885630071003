import type { BlockType } from "@/components/Block/entities";
import { useContext } from 'react';
import { ClinicContext, useGraphqlClient } from '@/App';
import { useGetLatestClinicConfigQuery } from '@/_graphql/graphql-client';
import { getUnixTime, startOfDay } from 'date-fns';

export interface BlockCardProps {
    block: BlockType;
    firstCardTime: number;
    quarterTimeStamp: number;
    isBlockEdit: boolean;
    blockId: string;
    endTime: number;
    onClickHandler: () => void;
}

interface BlockCardStyles {
    staticStyle: string;
    dynamicStyle: React.CSSProperties;
}

const isCardWidthConfig = (config: any): config is { __typename: "CardWidthConfig", cardWidth: number } => {
    return config?.__typename === "CardWidthConfig";
};

function toPastelColor(hex: string): string {
    if (hex === '#ddd') return hex;
    // カラーコードからRGB値を取得
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // RGB値を白色に近づける
    const pastelR = Math.round((r + 255) / 2);
    const pastelG = Math.round((g + 255) / 2);
    const pastelB = Math.round((b + 255) / 2);

    // RGB値をカラーコードに変換
    return `#${pastelR.toString(16).padStart(2, '0')}${pastelG.toString(16).padStart(2, '0')}${pastelB.toString(16).padStart(2, '0')}`;
}

export const BlockCard: React.FC<BlockCardProps> = (props) => {
    const { block, firstCardTime, isBlockEdit, blockId, endTime, onClickHandler } = props;
    const graphqlClient = useGraphqlClient();
    const clinicId = useContext(ClinicContext).clinic_id;

    // clinic設定の取得
    const { data: cardWidthList } = useGetLatestClinicConfigQuery(graphqlClient, {
        clinic_id: clinicId,
        current_date: getUnixTime(startOfDay(new Date())),
        config_type: 'card_width',
    });

    const configData = cardWidthList?.getLatestClinicConfig?.config ?? null;
    const cardWidth = configData !== null && isCardWidthConfig(configData) ? configData.cardWidth : 15;
    const { staticStyle, dynamicStyle } = computeStyles(block, firstCardTime, cardWidth, isBlockEdit, blockId, endTime);

    return (
        <div className="border-white">
            <div
                onClick={onClickHandler}
                className={staticStyle}
                style={dynamicStyle}
            >
                {block.remarks}
            </div>
        </div>
    );
}

export function computeStyles(
    block: BlockType,
    firstCardTime: number,
    cardWidth: number,
    isBlockEdit: boolean,
    blockId: string,
    endTime: number
): BlockCardStyles {
    // 静的スタイル
    const commonStyle = `px-3 absolute w-full h-full overflow-y-hidden text-xs text-black text-[0.925rem] leading-6`;
    const editStyle = "pointer-events-none opacity-50";
    const staticStyle = `${commonStyle} ${isBlockEdit ? editStyle : ''}`;

    const isSelected = block.id === blockId;
    const isActive = endTime !== block.endTime;

    // 動的スタイル
    const pastelColor = block?.color && block.color !== '' ? toPastelColor(block.color) : '#fff';
    const bgStyle = `repeating-linear-gradient(
        60deg,
        ${pastelColor} 0,
        ${pastelColor} 10px,
        #fff 10px,
        #fff 11px
    )`;

    // cardWidthに基づいて高さと位置を計算
    const durationMinutes = (block.endTime - block.startTime) / 60;
    const cardBoxNum = Math.ceil(durationMinutes / cardWidth);
    const height = cardBoxNum * 100 - 10;

    const startMinutes = (block.startTime - firstCardTime) / 60;
    const positionTop = (startMinutes / cardWidth) * 100;

    const dynamicStyle = {
        border: isSelected ? '3px solid #000000' : 'none',
        backgroundImage: bgStyle,
        opacity: isBlockEdit ? 0.5 : 1,
        top: `${positionTop}px`,
        height: `${height}px`,
        ...(isSelected && { boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.4) inset' }),
        ...(!isActive && { zIndex: 50 })
    };

    return { staticStyle, dynamicStyle };
};